<script>
  import { push } from "svelte-spa-router";

  import { initPasswordResetRequest } from "../../../shared/api";

  import AppButton from "../../../shared/components/AppButton/AppButton.svelte";

  import AppInput from "../../../shared/components/FormItems/AppInput/AppInput.svelte";

  import AuthForm from "../AuthForm.svelte";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  let email;
  let error;
  let ok;
  let loading = false;

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  async function handleSubmit(e) {
    e.preventDefault();
    loading = true;
    try {
      if (email) {
        await initPasswordResetRequest(email);
        ok = true;
        email = "";
      } else {
        error = "Devi inserire un indirizzo mail";
      }
    } catch (e) {
      if (e.errorCode === "ERR_USER_NOT_FOUND") {
        error = "Questo indirizzo mail non esiste nel sistema.";
      } else {
        error = "Errore durante la richiesta";
      }
    }
    loading = false;
  }
</script>

<AuthForm class={`PasswordReset ${$$props.class}`}>
  <form on:submit={handleSubmit} class="content">
    <h1 class="heading mb-s">Bentornato,</h1>
    <p class="helper mb-m">
      Inserisci di seguito l'indirizzo mail che hai usato per registrarti a
      eLT1. Ti verrà inviata una mail con il link che puoi uilizzare per
      resettare la tua password.
    </p>
    {#if error}
      <span class="block text-error mb-2 font-medium text-sm">{error}</span>
    {/if}
    {#if ok}
      <span class="block mb-2 font-medium text-sm text-success">
        Richiesta effettuata con successo. Controlla la tua posta elettronica.
      </span>
    {/if}
    <AppInput
      value={email}
      onChange={(v) => (email = v)}
      label="Indirizzo mail"
      class="mb-3"
    />
    <AppButton {loading} type="submit" class="mb-2">Conferma</AppButton>
    <AppButton {loading} type="button" on:click={() => push("/")} role="ghosted"
      >Torna al login</AppButton
    >
  </form>
</AuthForm>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .PasswordReset {
    .content {
      .heading {
        font-size: 22px;
        padding: 0;
      }
      .helper {
        font-size: 14px;
        color: $textMuted;
      }
    }
  }
</style>
