<script>
  //
  import IoMdArrowDropdown from "svelte-icons/io/IoMdArrowDropdown.svelte";
  import { AuthState, logout } from "../../../state/auth";
  import ActionsDropdown from "./ActionsDropdown.svelte";
  import { useOnclickOutside } from "../../../hooks/useOnclickOutside";
  import { push } from "svelte-spa-router";

  let dropdownNode;
  let dropdownOpen;

  $: user = $AuthState.user;
  $: closeOnClickOutside = useOnclickOutside(
    dropdownNode,
    () => (dropdownOpen = false)
  );
  $: $closeOnClickOutside;

  function toProfile() {
    // dropdownOpen = false;
    push("/profilo");
  }
  function handleLogout() {
    logout();
    push("/");
    dropdownOpen = false;
  }
</script>

<div class={`actions ${$$props.class}`}>
  <button
    class="action account"
    on:click={() => (dropdownOpen = !dropdownOpen)}
    bind:this={dropdownNode}
  >
    <div class="name">
      <!--  -->
      {user.email}
    </div>
    <div class="icon">
      <IoMdArrowDropdown />
    </div>
    <ActionsDropdown open={dropdownOpen} onClose={() => (dropdownOpen = false)}>
      <div class="dropdown-button" on:click={toProfile}>Visualizza profilo</div>
      <div class="dropdown-button logout-btn" on:click={handleLogout}>
        Logout
      </div>
    </ActionsDropdown>
  </button>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";
  $hpad: 20px;
  .actions {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    .action {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: relative;
      height: 40px;
      border: none;
      background-color: transparent;
      outline: none;
      margin: 0;
      padding: 0;
      &:not(:last-child) {
        margin-right: 20px;
      }

      &.logout {
        .icon {
          width: 25px;
          height: 25px;
          :global(svg) {
            stroke: $error;
            fill: $error;
          }
        }
      }

      &.account {
        .name {
          margin-right: 2px;
          font-size: 14px;
          line-height: 16px;
          font-weight: 600;
        }
        .icon {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          :global(svg) {
            fill: $textMuted;
          }
        }
      }
    }
    .dropdown-button {
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 200ms ease;
      padding: 25px 0px;
      font-weight: 500;

      &.logout-btn {
        color: $error;
      }

      &:hover {
        background-color: $grey200;
      }
    }
  }
</style>
