<script>
  import dayjs from "dayjs";
  import { push } from "svelte-spa-router";

  import Heading from "../../../../shared/components/Typography/Heading/Heading.svelte";
  import AppButton from "../../../../shared/components/AppButton/AppButton.svelte";
  import { SubmissionParamsStore } from "../submissionParams";
  import { AuthState } from "../../../../shared/state/auth";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function loadMore() {
    SubmissionParamsStore.loadNext(
      $AuthState.token,
      $SubmissionParamsStore.page,
      $SubmissionParamsStore.filters
    );
  }

  function toSubmissionDetail(submission) {
    return () => {
      push(`/compilazione/${submission?._id}`);
    };
  }
</script>

<div class={`submissions ${$$props.class}`}>
  <div class="grid">
    {#each $SubmissionParamsStore.submissions as submission, index (submission._id)}
      <div
        class="card"
        on:keydown={toSubmissionDetail(submission)}
        on:click={toSubmissionDetail(submission)}
      >
        <Heading type="h3" class="mb-m"
          >{submission.type === "standard"
            ? "Compilazione standard"
            : "Compilazione scheda"}</Heading
        >
        <div class="row">
          <div class="label">Inizio</div>
          <div class="value">
            {dayjs(submission.startedAt).format("DD MMM HH:mm")}
          </div>
        </div>
        <div class="row">
          <div class="label">Consegna</div>
          <div class="value">
            {dayjs(submission.endedAt).format("DD MMM HH:mm")}
          </div>
        </div>
        <div class="row">
          <div class="label">N. Esercizi</div>
          <div class="value">{submission.exerciseCount}</div>
        </div>
      </div>
    {/each}
  </div>
  {#if $SubmissionParamsStore.canLoadMore}
    <div class="mt-3 w-300 self-start">
      <AppButton
        role="secondary"
        loading={$SubmissionParamsStore.loading}
        on:click={loadMore}>Mostra di più</AppButton
      >
    </div>
  {/if}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .submissions {
    display: block;
    margin-top: $space-s;
    width: 100%;
    .grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, 300px);
      gap: 20px;
      .card {
        width: 100%;
        background-color: white;
        border-radius: 8px;
        padding: $space-m;
        box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.1);
        transition: all 200ms ease;
        cursor: pointer;

        &:hover {
          background-color: $primary200;
        }
        :global(h3) {
          text-align: center;
        }
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: $space-s;
          width: 100%;
          .label {
            font-weight: 500;
            font-size: 12px;
          }
          .value {
            font-size: 12px;
          }
        }
      }
    }
  }
</style>
