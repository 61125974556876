<script>
  export let value;
  export let onChange;
  import FaCheck from "svelte-icons/fa/FaCheck.svelte";
</script>

<div class={`radio ${$$props.class}`} class:selected={value}>
  <div class="radio-inner" />
  <input
    type="radio"
    on:change={(e) => {
      e.stopPropagation();
      onChange();
    }}
  />
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  $outer: 20px;
  $inner: 10px;
  $borderWidth: 2px;

  .radio {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: $borderWidth solid $grey300;
    width: $outer;
    height: $outer;
    cursor: pointer;
    transition: all 200ms ease;
    border-radius: 50%;

    position: relative;

    .radio-inner {
      width: $inner;
      height: $inner;
      border-radius: 50%;
      transition: all 200ms ease;
    }

    input {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 0;
      margin: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &.selected {
      border: $borderWidth solid $primary;

      .radio-inner {
        background-color: $primary;
      }
    }
  }
</style>
