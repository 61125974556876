<script>
  import SubmissionWidget from "../../../shared/components/SubmissionWidget/SubmissionWidget.svelte";

  import Heading from "../../../shared/components/Typography/Heading/Heading.svelte";
  import Paragraph from "../../../shared/components/Typography/Paragraph/Paragraph.svelte";
  import AppButton from "../../../shared/components/AppButton/AppButton.svelte";
  import dayjs from "dayjs";
  import { purgeField, buildSubmissionPayload } from "./service";
  import { createSubmission, uploadFile } from "../../../shared/api/index";
  import { asyncMap } from "../../../shared/toolkit/index";

  // ---------------------------------------
  // Imports
  // ---------------------------------------

  import { SheetState } from "../../../shared/state/sheet";
  import { AuthState } from "../../../shared/state/auth";
  import { onDestroy, onMount } from "svelte";
  import { push } from "svelte-spa-router";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  const auth = $AuthState;
  $: token = auth.token;

  let sheetStore = {};
  $: sheet = sheetStore.sheet ?? [];
  let form = {};
  let interval;
  let timeElapsed = { minutes: 0, seconds: 0 };
  let actionsOffsetTop = "100px";

  let loading = false;

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  $: {
    // Restore previous submission data from cache
    if (sheetStore.submission && !Object.keys(form).length) {
      form = sheetStore.submission;
    }
    [sheetStore.submission, form];
  }

  $: {
    // cleanup
    clearInterval(interval);

    interval =
      sheetStore?.meta?.startedAt &&
      setInterval(() => {
        const now = dayjs();
        const then = sheetStore.meta.startedAt;

        const diffInSeconds = Math.abs(now.diff(then, "seconds"));
        const minutesElapsed = Math.floor(diffInSeconds / 60);
        const secondsElapsed = diffInSeconds % 50;

        const formattedMinutes =
          minutesElapsed < 10 ? `0${minutesElapsed}` : `${minutesElapsed}`;
        const formattedSeconds =
          secondsElapsed < 10 ? `0${secondsElapsed}` : `${secondsElapsed}`;

        timeElapsed = { minutes: formattedMinutes, seconds: formattedSeconds };
      }, [100]);
  }

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  onMount(() => {
    const unsubscribe = SheetState.subscribe((state) => (sheetStore = state));
    window.addEventListener("scroll", updatePageScroll);
    return () => {
      unsubscribe();
    };
  });

  onDestroy(() => {
    interval && clearInterval(interval);
    window.removeEventListener("scroll", updatePageScroll);
  });

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function updatePageScroll() {
    const scroll = window.scrollY;
    if (scroll === 0) {
      actionsOffsetTop = "100px";
    } else if (scroll >= 70) {
      // navbar height = 70
      actionsOffsetTop = "30px";
    } else {
      const newScroll = 100 - scroll;
      actionsOffsetTop = `${newScroll}px`;
    }
  }

  const scrollToSection = (section) => (e) => {
    e.preventDefault();
    document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
  };

  const updateForm = (exerciseId) => {
    return (value) => {
      form = { ...form, [exerciseId]: value };
      purgeField(form).then((purged) => {
        SheetState.update((state) => ({ ...state, submission: purged }));
      });
    };
  };

  function handleCancel() {
    SheetState.set({ meta: null, submission: null, sheet: null });
    push("/esercizi");
  }

  async function preuploadFiles(payload) {
    const uploaded = {
      ...payload,
      data: await asyncMap(payload.data, async (exercise) => {
        return {
          ...exercise,
          answers: await asyncMap(exercise.answers, async (taskData) => {
            if (taskData.answer instanceof File) {
              const { url } = await uploadFile(taskData.answer, token).catch(
                () => ({ url: null })
              );
              return { ...taskData, answer: url };
            }
            return taskData;
          }),
        };
      }),
    };
    return uploaded;
  }

  async function handleSubmit() {
    loading = true;
    const stage1 = buildSubmissionPayload(form);
    const payload = await preuploadFiles(stage1);
    try {
      const result = await createSubmission(
        {
          ...payload,
          type: sheetStore?.meta?.type === "standard" ? "standard" : "sheet",
          startedAt: dayjs(sheetStore.meta.startedAt).toISOString(),
          endedAt: dayjs().toISOString(),
          sheet: sheetStore?.meta?.sheet,
        },
        token
      );
      const { _id } = result;
      SheetState.set({ sheet: null, meta: null, submission: null });
      push(`/compilazione/${_id}`);
    } catch (e) {
      alert("Errore durante l'invio dei dati.");
      console.log(e);
    } finally {
      loading = false;
    }
    loading = false;
  }
</script>

<div class={`submission ${$$props.class}`}>
  <div class="inner">
    <Heading class="mb-s">Compilazione</Heading>
    <Paragraph color="muted">
      Completa gli esercizi proposti oppure genera una nuova scheda nella pagina
      dedicata. Al termine della compilazione, potrai scegliere se chiedere una
      valutazione esplicita o un feedback da un tutor. In ogni caso potrai
      rivedere tutte le tue compulazioni con le relative soluzioni.
    </Paragraph>
    <div class="content mt-l">
      {#if sheetStore?.meta?.type === "standard"}
        {#each sheet as section}
          <Heading type="h2" class="mb-l" id={section.section._id}
            >{section.section.title}</Heading
          >
          {#each section.exercises as exercise, index}
            <SubmissionWidget
              {exercise}
              onChange={updateForm(exercise._id)}
              value={form?.[exercise._id]}
            />
            <div
              class="exercise-sep"
              class:none={index === section.exercises.length - 1}
            />
          {/each}
          <div class="sep" />
        {/each}
      {:else if sheet?.exercises}
        {#each sheet?.exercises as exercise, index}
          <SubmissionWidget
            {exercise}
            onChange={updateForm(exercise._id)}
            value={form?.[exercise._id]}
          />
          <div
            class="exercise-sep"
            class:none={index === sheet.exercises.length - 1}
          />
        {/each}
      {/if}
    </div>
  </div>
  <div class="actions" style={`top: ${actionsOffsetTop};`}>
    {#if sheetStore?.meta?.type === "standard"}
      <Heading type={"h3"} class="mb-s">Sezioni</Heading>
      <div class="sections mb-l">
        {#each sheet as section}
          <a
            href=""
            class="mb-xs"
            on:click={scrollToSection(section.section._id)}
            >{section.section.title}</a
          >
        {/each}
      </div>
    {/if}

    <Heading type="h3" class="mb-s">Informazioni</Heading>
    <div class="info mb-l">
      <div class="info-row mb-s">
        <span class="label">Tempo trascorso</span>
        <span class="value"
          >{`${timeElapsed.minutes}:${timeElapsed.seconds}`}</span
        >
      </div>
    </div>
    <AppButton class="mb-s" on:click={handleSubmit} {loading}
      >Consegna</AppButton
    >
    <AppButton role="delete" on:click={handleCancel}>Annulla</AppButton>
  </div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .submission {
    display: flex;
    justify-content: space-between;

    .none {
      display: none;
    }
    .inner {
      max-width: 700px;
      .content {
        .exercise-sep {
          width: 100%;
          height: 1px;
          background-color: $grey200;
          margin: $space-m 0px;
        }
        .sep {
          width: 100%;
          height: 2px;
          background-color: $grey500;
          margin: $space-m 0px;
        }
      }
    }
    .actions {
      width: 300px;
      position: fixed;
      right: 40px;
      padding: 10px;
      border-radius: 8px;
      box-shadow: 0px 6px 12px rgba($color: #000000, $alpha: 0.1);
      .sections {
        a {
          display: block;
          font-size: 12px;
          font-weight: 400;
          color: $textPrimary;
          transition: all 200ms ease;

          &:hover {
            text-decoration: none;
            color: $primary;
          }
        }
      }
      .info {
        .info-row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          span {
            display: block;
            font-size: 12px;
          }
          .label {
            font-weight: 500;
          }
          // .value {
          // }
        }
      }
    }
  }
</style>
