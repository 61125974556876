<script>
  import RadioButton from "../RadioButton/RadioButton.svelte";

  export let value;
  export let label;
  export let onChange;
</script>

<div
  class={`radio ${$$props.class}`}
  class:selected={value}
  on:click={onChange}
>
  <RadioButton class="mr-s" {value} {onChange} />
  <div class="label">{label}</div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .radio {
    height: $selectorHeight;
    border-radius: 8px;
    box-shadow: 0px 2px 12px rgba($color: #000000, $alpha: 0.06);
    background-color: white;

    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 200ms ease;
    cursor: pointer;

    .label {
      display: block;
    }

    &.selected {
      background-color: $primary200;
    }
  }
</style>
