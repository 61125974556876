<script>
  //
</script>

<footer class={`footer ${$$props.class}`}>
  By Aron Winkler<br />
  <a href="mailto:winkler.developer@gmail.com">winkler.developer@gmail.com</a>
</footer>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .footer {
    display: block;
    align-self: center;
    margin-top: auto;
    margin-bottom: 20px;
    color: $textMuted;
    font-size: 12px;
    text-align: center;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
</style>
