import { writable } from "svelte/store";

const storeKey = "elt1/userApp/sheet";

const initialState = {
  sheet: null,
  submission: null,
};

const createStore = () => {
  const persistGet = () => {
    const v = localStorage.getItem(storeKey);
    return v ? JSON.parse(v) : initialState;
  };
  const persistSet = (state) => {
    localStorage.setItem(storeKey, JSON.stringify(state));
  };

  const { set, subscribe, update } = writable(persistGet() ?? initialState);
  return {
    subscribe,
    set: (state) => {
      persistSet(state);
      set(state);
    },
    update: (updater) => {
      update((state) => {
        const newState = updater(state);
        persistSet(newState);
        return newState;
      });
    },
  };
};

export const SheetState = createStore();
