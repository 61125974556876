<script>
  import Divider from "../../../RoutedApp/LoggedApp/SubmissionDetail/components/Divider.svelte";

  import Heading from "../Typography/Heading/Heading.svelte";
  import ChoiceSolution from "./variants/ChoiceSolution.svelte";
  import FillSolution from "./variants/FillSolution.svelte";
  import OpenSolution from "./variants/OpenSolution.svelte";
  import Satzfelder from "./variants/SatzfelderSolution.svelte";
  import FileSolution from "./variants/FileSolution.svelte";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let exercise;
  export let answer;
  export let isLast;

  $: taskId = answer.task;
  $: userAnswer = answer.answer;

  $: task = getTaskDef(exercise, taskId);

  $: type = task?.type;

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function getTaskDef(exercise, task) {
    return exercise?.tasks?.find((taskDef) => taskDef._id === task);
  }
</script>

<div class={`widget ${$$props.class}`}>
  {#if task && type}
    <Heading type="h4" class="mt-s text-text-muted max-w-2xl mb-s"
      >{@html task.task}</Heading
    >
    {#if type === "open"}
      <OpenSolution {task} {userAnswer} />
    {:else if type === "m_choice" || type === "TF"}
      <ChoiceSolution {task} {userAnswer} />
    {:else if type === "SF"}
      <Satzfelder {task} {userAnswer} />
    {:else if type === "fill"}
      <FillSolution {task} {userAnswer} />
    {:else if type === "file"}
      <FileSolution {task} {userAnswer} />
    {/if}
    {#if !isLast}
      <Divider mode="task" class="my-3" />
    {/if}
  {/if}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";
</style>
