<script>
  export let open = false;
  export let onClose = () => {};

  let contentHeight = 100;
</script>

<div
  class={`dropdown ${$$props.class}`}
  class:open
  style={`height: ${open ? `${contentHeight}px` : "0px"};`}
>
  <div class="dropdown-inner">
    <!--  -->
    <slot {onClose} />
  </div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .dropdown {
    height: 0px;
    overflow: hidden;
    transition: all 200ms ease;

    position: absolute;
    top: 100%;
    right: 0;
    border-radius: 4px;

    background-color: $backgroundPrimary;
    box-shadow: 0px 4px 24px rgba($color: #000000, $alpha: 0.1);
    .dropdown-inner {
      width: 200px;
    }
  }
</style>
