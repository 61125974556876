import { writable } from "svelte/store";

export const getLineStyles = (index = 0, lineWidth, margin) => {
  const finalWidth = index <= 0 ? 0 : (lineWidth + margin) * index;
  return `
    width: ${lineWidth}px;
    left: ${finalWidth}px;
  `;
};

export const getLineWidths = () => {
  const widths = [];
  const nodes = document.querySelectorAll(".links .link-wrapper .link");
  nodes.forEach((node) => widths.push(node.getBoundingClientRect().width));
  return widths;
};

export function linesStore() {
  const { update, subscribe } = writable([], (set) => {
    const nodes = document.querySelectorAll(".links .link-wrapper .link");
    const observer = new ResizeObserver((entries) => {
      const newWidths = [];
      entries.forEach((node, index) => {
        if (node) {
          newWidths[index] = node.target.getBoundingClientRect().width;
        }
      });
      set(newWidths);
    });
    nodes.forEach((node) => observer.observe(node));
    return () => observer.disconnect();
  });

  return { subscribe };
}
