<script>
  export let value;
  export let error;
  export let label;
  export let onChange;
  export let onBlur;
  export let type = "text";
  let isFocused;

  function handleChange(e) {
    onChange && onChange(e.target.value);
  }

  function handleBlur() {
    isFocused = false;
    onBlur && onBlur();
  }

  function handleFocus() {
    isFocused = true;
  }
</script>

<div
  class={`input ${$$props.class}`}
  class:has-error={error}
  class:has-focus={isFocused}
  class:valid={!!value}
>
  <div class="input-content">
    {#if label}
      <label class="mb-s" for="">{label}</label>
    {/if}
    <input
      value={value ?? ""}
      on:blur={handleBlur}
      on:focus={handleFocus}
      on:input={handleChange}
      {type}
      autocomplete="off"
    />
  </div>
  {#if error}
    <small class="error-msg">{error}</small>
  {/if}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  $padding: 20px;

  .input {
    width: 100%;

    &.valid,
    &.has-focus {
      .input-content {
        background-color: $backgroundPrimary;
        border: 2px solid $primary;
      }

      .input-content label {
        top: 8px;
        font-size: 11px;
      }
    }

    .input-content {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px $padding;
      position: relative;

      border-radius: 8px;

      background-color: $grey200;
      transition: all 200ms ease;
      border: 2px solid $grey200;
      height: $selectorHeight;

      label {
        display: block;
        color: $textMuted;

        position: absolute;
        left: $padding;
        margin: 0;
        padding: 0;
        transition: all 200ms ease;
        transform: translateY(-50%);
        top: 50%;
      }
      input {
        display: block;
        flex: 1;
        width: 100%;
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        margin: 0;
        padding: 0;
        position: relative;
        top: 5px;

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }
      }
    }
    .error-msg {
      display: block;
    }
  }
</style>
