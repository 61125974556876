<script>
  import AppButton from "../../../shared/components/AppButton/AppButton.svelte";
  import { push } from "svelte-spa-router";

  const toLogin = () => push("/");
</script>

<div class="w-full h-full flex flex-col items-center justify-center">
  <p class="block mb-2 text-lg text-success font-medium text-center">
    Registrazione effettuata con successo.
  </p>
  <p class="block max-w-md text-center">
    Controlla la tua mail per verificare il tuo <b class="font-medium"
      >indirizzo mail</b
    >. Verficia anche la casella <b class="font-medium">spam</b>. Dopo questo
    controllo potrai iniziare a utlizzare liberamente
    <b class="font-medium text-primary-900">eLT1</b>.
  </p>
  <AppButton on:click={toLogin} class="mt-3">Torna al login</AppButton>
</div>
