<script>
  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let task;
  export let userAnswer;

  $: ({ rightAnswer } = task);

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------
</script>

<div class={`file ${$$props.class}`}>
  <div class="flex flex-row">
    <div
      class="flex flex-col align-center justify-center border-grey-300 border-2 p-2 rounded-lg w-300 h-300 mr-3"
    >
      {#if userAnswer}
        <a class="block w-full h-full" href={userAnswer}>
          <img
            class="block w-full h-full object-contain"
            src={userAnswer}
            alt="User uploaded solution"
          />
        </a>
      {:else}
        <span class="missing text-center font-bold text-error"
          >Nessuna immagine caricata</span
        >
      {/if}
    </div>
    <div
      class="flex align-center justify-center border-primary-900 border-2 p-2 rounded-lg w-300 h-300"
    >
      {#if rightAnswer && rightAnswer !== "url"}
        <a class="block w-full h-full" href={rightAnswer}>
          <img
            class="block w-full h-full object-contain"
            src={rightAnswer}
            alt="User uploaded solution"
          />
        </a>
      {:else}
        <div class="missing text-center font-bold text-error ">
          Non è ancora disponibile una soluzione per questo esercizio
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .missing {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
