<script>
  export let task;
  export let value;
  export let onChange;

  $: split = task.gapped_text.replace(/\n/g, "<br />").split("_");

  function handleInputChange(index) {
    return function (e) {
      const newValue = e.target.value;
      let clone = [...(value ?? [])];
      clone[index] = newValue;
      clone = clone.map((v) => v || "");
      onChange(clone);
    };
  }
</script>

<div class={`fill ${$$props.class}`}>
  {#each split as part, index}
    <span>{@html part}</span>
    {#if index !== split.length - 1}
      <input value={value?.[index] ?? ""} on:input={handleInputChange(index)} />
    {/if}
  {/each}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .fill {
    input {
      border: none;
      outline: none;
      border-bottom: 1px solid $primary500;
      padding: 0;
    }
  }
</style>
