<script>
  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let task;
  export let userAnswer;

  $: ({ rightAnswer } = task);

  $: split = task.gapped_text.replace(/\n/g, "<br />").split("_");

  $: parsedUserAnswer = JSON.parse(userAnswer);
  $: parsedRightAnswer = JSON.parse(rightAnswer);

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function getAnswerData(index) {
    const user = parsedUserAnswer[index];
    const correct = parsedRightAnswer[index];
    return {
      user,
      correct,
      isCorrect: user === correct,
    };
  }
</script>

<div class={`fill ${$$props.class} max-w-lg`}>
  {#each split as part, index}
    <span class="text-base">{@html part}</span>
    {#if index !== split.length - 1}
      <span>
        <span
          class="font-medium text-base"
          class:text-error={!getAnswerData(index).isCorrect}
          class:text-success={getAnswerData(index).isCorrect}
          >{getAnswerData(index).user ?? "nessuna risposta"}</span
        >
        {#if !getAnswerData(index).isCorrect}
          <span class="text-success font-medium text-base">
            ({getAnswerData(index).correct})
          </span>
        {/if}
      </span>
    {/if}
  {/each}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";
</style>
