<script>
  import Select from "svelte-select";
  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let options = [];
  export let value = null;
  export let onSelect = (value) => {};
  export let label;

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function handleSelect(event) {
    const { detail } = event;
    onSelect(detail.value);
  }
</script>

<div class={`Select ${$$props.class}`}>
  {#if label}
    <span class="block text-text-muted text-base mb-1">{label}</span>
  {/if}
  <Select
    isClearable={false}
    items={options}
    value={options.find((opt) => opt.value === value)}
    on:select={handleSelect}
  />
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .Select {
  }
</style>
