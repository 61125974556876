<script>
  //
  import Router from "svelte-spa-router";
  import Layout from "../../shared/components/Layout/Layout.svelte";
  import CustomSheets from "./CustomSheets/CustomSheets.svelte";
  import Home from "./Home/Home.svelte";
  import Profile from "./Profile/Profile.svelte";
  import SheetSetup from "./SheetSetup/SheetSetup.svelte";
  import Submission from "./Submission/Submission.svelte";
  import SubmissionDetail from "./SubmissionDetail/SubmissionDetail.svelte";
  const routes = {
    "/": Home,
    "/esercizi": SheetSetup,
    "/compilazione/:id": SubmissionDetail,
    "/compilazione": Submission,
    "/profilo": Profile,
    "/schede": CustomSheets,
  };
</script>

<div class={`LoggedApp ${$$props.class}`}>
  <Layout>
    <Router {routes} />
  </Layout>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";
</style>
