<script>
  import Footer from "./Footer.svelte";

  import Header from "./Header.svelte";

  //
</script>

<div class={`layout ${$$props.class}`}>
  <Header />
  <main>
    <slot />
  </main>
  <Footer />
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    main {
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
    }
  }
</style>
