<script>
  import { onMount } from "svelte";

  import { AuthState } from "../../../../shared/state/auth";
  import AppInput from "../../../../shared/components/FormItems/AppInput/AppInput.svelte";
  import AppButton from "../../../../shared/components/AppButton/AppButton.svelte";
  import { resetPasswordFromSettings } from "../../../../shared/api";
  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  let token = "";
  let loading = false;
  let error = "";

  let values = {
    oldPassword: "",
    repeatPassword: "",
    newPassword: "",
  };

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  onMount(() => {
    const unsubscribe = AuthState.subscribe((v) => (token = v.token));
    return () => {
      unsubscribe();
    };
  });

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  async function handleSubmit(e) {
    e.preventDefault();
    const { oldPassword, newPassword, repeatPassword } = values;
    if (!(newPassword === repeatPassword)) {
      error = "Le password non combaciano";
    }
    try {
      await resetPasswordFromSettings(oldPassword, newPassword, token);
      alert("Password cambiata con successo");
      values = {};
      error = "";
    } catch (e) {
      console.log(e);
      error = "Errore durante il cambio della password. Contattare l'admin.";
    }
  }

  function updateValues(field) {
    return (value) => {
      values = { ...values, [field]: value };
    };
  }
</script>

<form on:submit={handleSubmit} class={`PasswordChange ${$$props.class} mt-3`}>
  <span class="block mb-2 font-medium text-text-muted">Cambio password</span>
  {#if error}
    <span class="text-error font-medium text-sm">{error}</span>
  {/if}
  <AppInput
    value={values.oldPassword}
    onChange={updateValues("oldPassword")}
    label="Vecchia password"
    class="input mb-2 w-300"
    type="password"
  />
  <AppInput
    value={values.newPassword}
    onChange={updateValues("newPassword")}
    label="Nuova password"
    class="input mb-2 w-300"
    type="password"
  />
  <AppInput
    value={values.repeatPassword}
    onChange={updateValues("repeatPassword")}
    label="Ripeti nuova password"
    class="input mb-2 w-300"
    type="password"
  />
  <AppButton {loading} type="submit" role="ghosted" class="button w-300 mt-3"
    >Conferma cambio password</AppButton
  >
</form>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .PasswordChange {
    :global(.input) {
      width: 300px !important;
    }
    :global(.button) {
      width: 300px !important;
    }
  }
</style>
