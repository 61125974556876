<script lang="ts">
  export let color: "primary" | "muted";
</script>

<p class={`paragraph ${$$props.class} ${color}`}><slot /></p>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .paragraph {
    font-size: 14px;
    &.primary {
      color: $textPrimary;
    }
    &.muted {
      color: $textMuted;
    }
  }
</style>
