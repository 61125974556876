<script>
  import AppSelect from "../../../../shared/components/AppSelect/AppSelect.svelte";
  import dayjs from "dayjs";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let onFilter = () => {};

  let filters = {
    dateRange: "week",
    type: "off",
  };

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function updateFilters(field) {
    return (value) => {
      filters = { ...filters, [field]: value };
      onFilter(buildFilters(filters));
    };
  }

  function buildFilters(filters) {
    const { type, dateRange } = filters;
    return {
      type,
      ...getDateRange(dateRange),
    };
  }

  function getDateRange(dateRange) {
    const today = dayjs();
    const todayISO = today.toISOString();
    const after = "after";
    const before = "before";
    switch (dateRange) {
      case "week":
        return {
          [after]: today.subtract(1, "week").toISOString(),
          [before]: todayISO,
        };
      case "month":
        return {
          [after]: today.subtract(1, "month").toISOString(),
          [before]: todayISO,
        };
      case "year":
        return {
          [after]: today.subtract(1, "year").toISOString(),
          [before]: todayISO,
        };
    }
  }
</script>

<div class={`Filters ${$$props.class} flex flex-row items-center`}>
  <AppSelect
    value={filters.dateRange}
    class="select mr-2"
    onSelect={updateFilters("dateRange")}
    label="Periodo"
    options={[
      { label: "Ultima settimana", value: "week" },
      { label: "Ulimo mese", value: "month" },
      { label: "Ulimo anno", value: "year" },
    ]}
  />
  <AppSelect
    value={filters.type}
    class="select"
    onSelect={updateFilters("type")}
    label="Tipologia"
    options={[
      { label: "Tutti", value: "off" },
      { label: "Eserciziario", value: "standard" },
      { label: "Schede", value: "sheet" },
    ]}
  />
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .Filters {
    :global(.select) {
      width: 200px;
    }
  }
</style>
