<script>
  import { onMount } from "svelte";

  import { AuthState } from "../../../shared/state/auth";
  import Heading from "../../../shared/components/Typography/Heading/Heading.svelte";
  import ProfileSectionTitle from "./ProfileSectionTitle.svelte";
  import UserSubmissions from "./sections/UserSubmissions.svelte";
  import PasswordChange from "./sections/PasswordChange.svelte";
  import SubmissionFilter from "./sections/SubmissonFilters.svelte";
  import { SubmissionParamsStore } from "./submissionParams";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  let token;

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  onMount(() => {
    const unlisten = AuthState.subscribe((data) => {
      token = data.token;
      SubmissionParamsStore.load(token, $SubmissionParamsStore.filters);
    });

    return () => {
      unlisten();
    };
  });

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function onFilterChange(filters) {
    SubmissionParamsStore.load(token, filters);
  }
</script>

<div class={`profile ${$$props.class}`}>
  <Heading class="mb-l">Il tuo profilo</Heading>
  <div class="section">
    <ProfileSectionTitle>
      <span>Le tue compilazioni</span>
      <SubmissionFilter onFilter={onFilterChange} slot="right" />
    </ProfileSectionTitle>
    <UserSubmissions />
  </div>
  <div class="section">
    <ProfileSectionTitle>Notifiche</ProfileSectionTitle>
  </div>
  <div class="section">
    <ProfileSectionTitle>Impostazioni</ProfileSectionTitle>
    <PasswordChange />
  </div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .profile {
    display: block;
    .section {
      margin-bottom: $space-m;
    }
  }
</style>
