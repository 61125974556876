<script>
  import Heading from "../../../shared/components/Typography/Heading/Heading.svelte";
  import Paragraph from "../../../shared/components/Typography/Paragraph/Paragraph.svelte";
  import { getStandardSectionNames, getSheet } from "../../../shared/api";
  import { AuthState } from "../../../shared/state/auth";
  import { SettingsState } from "../../../shared/state/settings";
  import { SheetState } from "../../../shared/state/sheet";
  import FloatingCheckbox from "../../../shared/components/FormItems/FloatingCheckbox/FloatingCheckbox.svelte";
  import AppButton from "../../../shared/components/AppButton/AppButton.svelte";
  import RadioGroup from "../../../shared/components/FormItems/RadioGroup/RadioGroup.svelte";
  import { translateSheetResponse } from "../../../shared/toolkit";
  import dayjs from "dayjs";
  import { push } from "svelte-spa-router";

  const auth = $AuthState;
  const settings = $SettingsState;
  let sectionNames = [];

  getStandardSectionNames(auth.token).then((data) => (sectionNames = data));

  $: perSection = settings.perSection ?? 1;
  $: selecetedSections = settings.sections ?? [];

  function handleSectionSelected(id) {
    return () => {
      if (selecetedSections?.includes(id)) {
        selecetedSections = selecetedSections.filter((i) => i !== id);
      } else {
        selecetedSections = [...selecetedSections, id];
      }
    };
  }

  function handlePerSectionSelected(value) {
    perSection = value;
  }

  $: handleSubmit = async () => {
    const sheet = await getSheet(selecetedSections, perSection, auth.token);
    const translated = translateSheetResponse(sheet);
    SheetState.set({
      sheet: translated,
      submission: [],
      meta: { startedAt: dayjs(), type: "standard" },
    });
    SettingsState.set({ sections: selecetedSections, perSection: perSection });
    push("/compilazione");
  };
</script>

<div class={`sheet-setup ${$$props.class} mt-l`}>
  <Heading class="mb-s">Costruisci la tua scheda</Heading>
  <div class="paragraph">
    <Paragraph color="muted">
      Specifica i parametri della tua scheda per personalizzare gli esercizi che
      ti vengono presentati. Creare una nuova scheda elimina quella che stai
      attualmente compilando.
    </Paragraph>
  </div>
  <div class="sections mt-l mb-l">
    <Heading class="mb-m" type="h2">Seleziona le sezioni</Heading>
    {#each sectionNames as section}
      <FloatingCheckbox
        label={section.title}
        value={selecetedSections.includes(section._id)}
        onChange={handleSectionSelected(section._id)}
        class="mb-s"
      />
    {/each}
  </div>
  <div class="section-count">
    <Heading type="h2" class="mb-m">Esercizi per sezione</Heading>
    <RadioGroup
      options={[
        { label: "1 esercizio per sezione", value: 1 },
        { label: "2 esercizi per sezione", value: 2 },
        { label: "3 esercizi per sezione", value: 3 },
        { label: "4 esercizi per sezione", value: 4 },
        { label: "5 esercizi per sezione", value: 5 },
      ]}
      value={perSection}
      onChange={handlePerSectionSelected}
    />
  </div>
  <AppButton on:click={handleSubmit} class="mt-l">Genera scheda</AppButton>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .sheet-setup {
    align-self: center;
    .paragraph {
      max-width: 600px;
    }
  }
</style>
