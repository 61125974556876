<script>
  import AppButton from "../../../shared/components/AppButton/AppButton.svelte";
  import { push } from "svelte-spa-router";

  const toLogin = () => push("/");
</script>

<div class="w-full h-full flex flex-col items-center justify-center">
  <p class="block mb-2 text-lg text-success font-medium text-center">
    Password cambiata con successo.
  </p>

  <AppButton on:click={toLogin} class="mt-3">Torna al login</AppButton>
</div>
