<script>
  import FaPlus from "svelte-icons/fa/FaPlus.svelte";
  import AppButton from "../../AppButton/AppButton.svelte";

  // export let task;
  export let value;
  export let onChange;

  let fileUrl;

  $: if (
    value &&
    typeof value === "object" &&
    value !== null &&
    value instanceof File
  ) {
    fileUrl = URL.createObjectURL(value);
  } else {
    fileUrl = value;
  }

  function handleUpload(e) {
    const file = e.target.files[0];
    file && onChange(file);
  }

  function resetValue() {
    onChange(null);
  }
</script>

<div class={`file ${$$props.class}`}>
  {#if !fileUrl}
    <div class="selector">
      <FaPlus />
      <input
        type="file"
        class="input-inner"
        on:change={handleUpload}
        accept="image/*"
      />
    </div>
  {/if}

  <div class="preview-row mt-m">
    {#if fileUrl}
      <div class="preview">
        <div class="img-wrapper mb-s">
          <img src={fileUrl} alt="" class="" />
        </div>
        <AppButton role="delete" on:click={resetValue}>Elimina</AppButton>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .file {
    .selector {
      position: relative;
      width: 120px;
      height: 120px;
      border: 3px dashed $grey500;
      transition: all 200ms ease;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      overflow: hidden;

      :global(svg) {
        width: 40px;
        height: 40px;
        fill: $grey500;
        transition: all 200ms ease;
      }

      .input-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: block;
        opacity: 0;
        cursor: pointer;
      }

      &:hover {
        border: 3px dashed $primary;

        :global(svg) {
          fill: $primary;
        }
      }
    }
    .preview-row {
      .preview {
        width: 200px;

        .img-wrapper {
          padding: 10px;
          border: 1px solid $grey300;
          width: 100%;
          height: 200px;
          border-radius: 4px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
          border: none;
          outline: none;
        }
      }
    }
  }
</style>
