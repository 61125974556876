<script>
  import AuthLayout from "./AuthLayout.svelte";
  import Router from "svelte-spa-router";
  import Login from "./Login/Login.svelte";
  import Signup from "./Signup/Signup.svelte";
  import Activate from "./Activate/Activate.svelte";
  import CheckMail from "./CheckMail/CheckMail.svelte";
  import PasswordResetToken from "./PasswordReset/PasswordResetToken.svelte";
  import PasswordReset from "./PasswordReset/PasswordReset.svelte";
  import PasswordResetSuccess from "./PasswordReset/PasswordResetSuccess.svelte";
  import Redirect from "./_redirect.svelte";

  const routes = {
    "/": Login,
    "/signup": Signup,
    "/attivazione/:token": Activate,
    "/check-mail": CheckMail,
    "/password-reset/:token": PasswordResetToken,
    "/password-reset": PasswordReset,
    "/password-reset-success": PasswordResetSuccess,
    "*": Redirect,
  };
</script>

<AuthLayout class="auth">
  <Router {routes} />
</AuthLayout>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
</style>
