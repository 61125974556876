<script>
  import Checkbox from "../Checkbox/Checkbox.svelte";
  export let value = false;
  export let onChange = () => {};
  export let label = "";
</script>

<div
  class={`checkbox ${$$props.class}`}
  class:checked={value}
  on:click={onChange}
>
  <Checkbox {value} {onChange} />
  <div class="label ml-s">{label}</div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .checkbox {
    height: $selectorHeight;
    border-radius: 8px;
    box-shadow: 0px 2px 12px rgba($color: #000000, $alpha: 0.06);
    background-color: white;

    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: all 200ms ease;
    cursor: pointer;

    .label {
      display: block;
    }

    &.checked {
      background-color: $primary200;
    }
  }
</style>
