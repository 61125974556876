<script lang="ts">
  import FloatingRadioButton from "../FlotaingRadioButton/FloatingRadioButton.svelte";

  export let value;
  export let onChange;
  export let options: { label: string; value: any }[] = [];
</script>

<div class={`radio-group ${$$props.class}`}>
  {#each options as option}
    <FloatingRadioButton
      value={option.value === value}
      label={option.label}
      onChange={() => onChange(option.value)}
      class="mb-s"
    />
  {/each}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .radio-group {
  }
</style>
