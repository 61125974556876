<script>
  import { getCustomSheet, getCustomSheets } from "../../../shared/api";

  import Heading from "../../../shared/components/Typography/Heading/Heading.svelte";
  import { AuthState } from "../../../shared/state/auth";
  import { translateCustomSheetResponse } from "../../../shared/toolkit";
  import { SheetState } from "../../../shared/state/sheet";

  import dayjs from "dayjs";
  import { push } from "svelte-spa-router";
  import Paragraph from "../../../shared/components/Typography/Paragraph/Paragraph.svelte";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  $: auth = $AuthState;
  $: token = auth.token;

  let sheets = [];

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  $: {
    getCustomSheets(token).then((data) => (sheets = data));
  }

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function mapSheetType(type) {
    const MAP = {
      normal: "Scheda standard",
      exam: "Esame passato",
    };
    return MAP[type] ?? type;
  }

  function handleSheetClick(sheet) {
    return async () => {
      const downloaded = await getCustomSheet(sheet._id, token);
      const translated = translateCustomSheetResponse(downloaded);
      const newState = {
        sheet: translated,
        submission: [],
        meta: {
          startedAt: dayjs(),
          type: "customSheet",
          sheet: downloaded?._id,
        },
      };
      SheetState.set(newState);
      push("/compilazione");
    };
  }
</script>

<div class={`CustomSheets ${$$props.class}`}>
  <Heading class="mb-s">Schede</Heading>
  <div class="paragraph">
    <Paragraph color="muted">
      Qui sotto trovi schede di esercizi costruiti apposta da tutor o docenti,
      oppure esami passati adattati ad eLT1.
    </Paragraph>
  </div>
  <div class="sheets">
    {#each sheets as sheet (sheet?._id)}
      <div class="sheet" on:click={handleSheetClick(sheet)}>
        <div class="name text-center text-text-muted text-sm mb-2">
          {mapSheetType(sheet.type)}
        </div>
        <div class="title text-center font-medium text-lg">{sheet.title}</div>
      </div>
    {/each}
  </div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .CustomSheets {
    .paragraph {
      max-width: 600px;
    }
    .sheets {
      display: grid;
      grid-template-columns: repeat(auto-fit, 300px);
      margin-top: $space-m;
      .sheet {
        width: 100%;
        background-color: white;
        border-radius: 8px;
        padding: $space-m;
        box-shadow: 0px 4px 8px rgba($color: #000000, $alpha: 0.1);
        transition: all 200ms ease;
        cursor: pointer;
        height: 150px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
</style>
