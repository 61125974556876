<script lang="ts">
  import { confirmEmailAddressWithToken } from "../../../shared/api";
  import AppButton from "../../../shared/components/AppButton/AppButton.svelte";
  import { push } from "svelte-spa-router";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  let status: "pending" | "error" | "success" = "pending";
  export let params: any = {};
  $: token = params.token;

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  $: {
    if (token) {
      confirmEmailAddressWithToken(token)
        .then(() => (status = "success"))
        .catch((e) => {
          console.log(e);
          status = "error";
        });
    }
  }

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function toLogin() {
    push("/");
  }
</script>

<div class={`Activate ${$$props.class}`}>
  {#if status === "pending"}
    <div class="pending">Attivazione in corso...</div>
  {:else if status === "error"}
    <div class="error p-3 w-300">
      <span class="block text-error font-medium text-center mb-2"
        >Errore durante la verifica dell'indirizzo</span
      >
      <AppButton on:click={toLogin}>Torna al login</AppButton>
    </div>
  {:else if status === "success"}
    <div class="success p-3 w-300">
      <span class="block text-success font-medium text-center mb-2"
        >Indirizzo mail verificato con successo</span
      >
      <AppButton on:click={toLogin}>Torna al login</AppButton>
    </div>
  {/if}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .Activate {
  }
</style>
