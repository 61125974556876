<script>
  import Heading from "../Typography/Heading/Heading.svelte";

  import TaskLabel from "./TaskLabel.svelte";

  import Open from "./Variants/Open.svelte";
  import Choice from "./Variants/Choice.svelte";
  import Fill from "./Variants/Fill.svelte";
  import File from "./Variants/File.svelte";
  import SatzFelder from "./Variants/SatzFelder.svelte";

  export let exercise;
  export let onChange;
  export let value;

  const updateTask = (taskId) => (newValue) => {
    onChange({ ...value, [taskId]: newValue });
  };
</script>

<div class={`submission ${$$props.class}`}>
  <Heading type="h3" class="mb-m">{@html exercise.mainTask}</Heading>
  {#each exercise.tasks as task}
    <div class="task">
      <TaskLabel class="mb-m">{@html task.task}</TaskLabel>
      {#if task.type === "open"}
        <Open
          {task}
          value={value?.[task._id]}
          onChange={updateTask(task._id)}
        />
      {:else if task.type === "m_choice"}
        <Choice
          {task}
          value={value?.[task._id]}
          onChange={updateTask(task._id)}
        />
      {:else if task.type === "TF"}
        <Choice
          task={{
            ...task,
            wrongAnswers:
              task?.select.filter((s) => s !== task.rightAnswer) ?? [],
          }}
          value={value?.[task._id]}
          onChange={updateTask(task._id)}
        />
      {:else if task.type === "fill"}
        <Fill
          {task}
          value={value?.[task._id]}
          onChange={updateTask(task._id)}
        />
      {:else if task.type === "file"}
        <File
          {task}
          value={value?.[task._id]}
          onChange={updateTask(task._id)}
        />
      {:else if task.type === "SF"}
        <SatzFelder
          {task}
          value={value?.[task._id]}
          onChange={updateTask(task._id)}
        />
      {/if}
    </div>
  {/each}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .submission {
    .task {
      margin-bottom: $space-l;
    }
  }
</style>
