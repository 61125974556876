<script>
  import Heading from "../../../shared/components/Typography/Heading/Heading.svelte";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------
</script>

<div class={`profile-title ${$$props.class}`}>
  <div class="content-row">
    <Heading type="h2"><slot /></Heading>
    <div class="extra">
      <slot name="right" />
    </div>
  </div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .profile-title {
    padding-bottom: 10px;
    border-bottom: 1px solid $grey300;
    width: 100%;
    .content-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
</style>
