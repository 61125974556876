export class ApiError extends Error {
  constructor(body, statusCode) {
    const { message, error } = body;
    super(message);
    this.errorBody = body;
    this.errorMessage = message;
    this.errorCode = error;
    this.statusCode = statusCode;
  }
}
