<script>
  //
</script>

<div class={`AuthForm ${$$props.class}`}>
  <slot />
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";

  .AuthForm {
    width: 500px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 4px 24px rgba($color: #000000, $alpha: 0.1);

    background-color: $backgroundPrimary;
  }
</style>
