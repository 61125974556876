<script>
  // export let task;
  export let value;
  export let onChange;

  function handleInputChange(index) {
    return function (e) {
      const newValue = e.target.value;
      let clone = [...(value ?? [])];
      clone[index] = newValue;
      clone = clone.map((v) => v || "");
      onChange(clone);
    };
  }
</script>

<div class={`felder ${$$props.class}`}>
  <div class="input-row">
    <div class="label">VVF</div>
    <div class="input">
      <input value={value?.[0] || ""} on:input={handleInputChange(0)} />
    </div>
  </div>

  <div class="input-row">
    <div class="label">VF</div>
    <div class="input">
      <input value={value?.[1] || ""} on:input={handleInputChange(1)} />
    </div>
  </div>

  <div class="input-row">
    <div class="label">LSK</div>
    <div class="input">
      <input value={value?.[2] || ""} on:input={handleInputChange(2)} />
    </div>
  </div>

  <div class="input-row">
    <div class="label">MF</div>
    <div class="input">
      <input value={value?.[3] || ""} on:input={handleInputChange(3)} />
    </div>
  </div>

  <div class="input-row">
    <div class="label">RSK</div>
    <div class="input">
      <input value={value?.[4] || ""} on:input={handleInputChange(4)} />
    </div>
  </div>

  <div class="input-row">
    <div class="label">NF</div>
    <div class="input">
      <input value={value?.[5] || ""} on:input={handleInputChange(5)} />
    </div>
  </div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .felder {
    .input-row {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: $space-xs;
    }
    .label {
      padding: 5px;
      height: 40px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid $grey500;

      font-weight: 500;
      font-size: 12px;
    }
    .input {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: 1px solid $grey500;
      border-left: none;
      padding: 5px;
      width: 300px;

      input {
        display: block;
        height: 100%;
        width: 100%;
        border: none;
        outline: none;
        margin: 0;
        padding: 0;
      }
    }
  }
</style>
