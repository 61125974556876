<script>
  import SolutionWidget from "../../../../shared/components/SolutionWidget/SolutionWidget.svelte";

  import Heading from "../../../../shared/components/Typography/Heading/Heading.svelte";
  import Divider from "./Divider.svelte";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let submission;
  $: data = submission.data;

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------
</script>

<div class={`answers ${$$props.class}`}>
  <Heading type="h2" class="mb-s underline">Compilazione</Heading>
  {#each data as { exercise, answers }, index}
    <Heading type={"h3"} class="mb-xs max-w-2xl"
      >{@html exercise.mainTask}</Heading
    >
    {#each answers as answer, index}
      <SolutionWidget
        {exercise}
        {answer}
        isLast={index === answers.length - 1}
        class="mb-3"
      />
    {/each}
    {#if index !== data.length - 1}
      <!-- content here -->
      <Divider mode="exercise" class="my-4" />
    {/if}
  {/each}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";
</style>
