<script>
  import dayjs from "dayjs";
  import Heading from "../../../../shared/components/Typography/Heading/Heading.svelte";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let submission = {};

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------
</script>

<div class={`submission ${$$props.class} mt-3`}>
  {#if submission}
    <Heading type="h2" class="mb-s underline">Informazioni</Heading>
    <div class="flex flex-row items-center justify-between w-300 mb-1">
      <span class="block font-medium text-base">Data inizio</span>
      <span class="block text-base"
        >{dayjs(submission.startedAt).format("DD MMM HH:mm")}
      </span>
    </div>
    <div class="flex flex-row items-center justify-between w-300 mb-1">
      <span class="block font-medium text-base">Data fine</span>
      <span class="block text-base"
        >{dayjs(submission.endedAt).format("DD MMM HH:mm")}
      </span>
    </div>
  {/if}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";
</style>
