<script>
  import Heading from "../Typography/Heading/Heading.svelte";
</script>

<Heading class={`label ${$$props.class}`} type="h4">
  <slot />
</Heading>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  :global(label) {
  }
</style>
