<script lang="ts">
  export let role: "primary" | "secondary" | "ghosted" | "delete" = "primary";
  import { Circle as Loader } from "svelte-loading-spinners";
  export let type = "button";
  export let loading: boolean = false;
</script>

<button class={`AppButton ${$$props.class} ${role}`} on:click {type}>
  {#if loading}
    <div class="mr-2">
      <Loader color={"white"} size={25} />
    </div>
  {/if}
  <span>
    <slot />
  </span>
</button>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  $borderWidth: 2px;

  .AppButton {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $buttonHeight;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;

    font-size: 14px;
    font-weight: 700;

    &.primary {
      background-color: $primary;
      color: $textSecondary;
      border: $borderWidth solid $primary;
    }

    &.secondary {
      background-color: $primary200;
      color: $primary;
      border: $borderWidth solid $primary200;
    }

    &.ghosted {
      background-color: $backgroundPrimary;
      color: $primary;
      border: $borderWidth solid $primary;
    }

    &.delete {
      background-color: $backgroundPrimary;
      color: $error;
      border: $borderWidth solid $error;
    }

    &.success {
      background-color: $backgroundPrimary;
      color: $success;
      border: $borderWidth solid $success;
    }
  }
</style>
