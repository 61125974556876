<script>
  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let task;
  export let userAnswer;

  $: ({ rightAnswer } = task);

  const fields = ["VVF", "VF", "LSK", "MF", "RSK", "NF"];

  $: parsedRightAnswer = rightAnswer && parseFields(rightAnswer);
  $: parsedUserAnswer = userAnswer && parseFields(userAnswer);

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  function parseFields(str) {
    const parsed = {};

    const json = JSON.parse(str);
    fields.forEach((field, index) => {
      parsed[field] = json[index] ?? "";
    });
    return parsed;
  }
</script>

<div class={`satz ${$$props.class} `}>
  {#each fields as field, index}
    {#if parsedRightAnswer && parsedUserAnswer}
      <div
        class="flex flex-row max-w-md py-2"
        class:border-b-1={index !== fields.length - 1}
      >
        <div class="flex items-center justify-start w-1/5 font-medium">
          {field}
        </div>
        <div class="flex items-center justify-start flex-1">
          {parsedUserAnswer[field]}
        </div>
        <div class="flex items-center justify-start flex-1 text-primary-900">
          {parsedRightAnswer[field]}
        </div>
      </div>
    {/if}
  {/each}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";
</style>
