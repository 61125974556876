<script>
  import { onMount } from "svelte";
  import { AuthState } from "../../../shared/state/auth";
  import { getSubmission } from "../../../shared/api/index";
  import Heading from "../../../shared/components/Typography/Heading/Heading.svelte";
  import SubmissionData from "./components/SubmissionData.svelte";
  import SubmissionAnswers from "./components/SubmissionAnswers.svelte";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let params = {};
  const { id } = params;
  let token;
  let submission = {};

  $: {
    token &&
      id &&
      getSubmission(id, token)
        .then((data) => {
          submission = data;
        })
        .catch((e) => {
          console.log(e);
        });
  }

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  onMount(() => {
    AuthState.subscribe((data) => (token = data.token));
  });

  // ---------------------------------------
  // Component functions
  // ---------------------------------------
</script>

<div class={`submission ${$$props.class}`}>
  {#if submission._id}
    <Heading type="h1">Compilazione {submission._id}</Heading>
    <SubmissionData {submission} class="mb-4" />
    <SubmissionAnswers {submission} />
  {/if}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";
</style>
