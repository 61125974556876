<script>
  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  export let task;
  export let userAnswer;

  $: ({ rightAnswer } = task);

  $: isCorrect = userAnswer === rightAnswer;

  let labelClass = "block font-medium font text-sm mb-1";

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------
</script>

<div class={`choice ${$$props.class} flex flex-row align-start mt-2`}>
  <div class="mr-4 w-300">
    <span class={labelClass}>La tua risposta</span>
    <span
      class="block text-base"
      class:text-success={isCorrect}
      class:text-error={!isCorrect}>{userAnswer}</span
    >
  </div>
  <div class="w-auto max-w-sm">
    <span class={labelClass}>Risposta corretta</span>
    <span class="block text-base text-primary-900 font-medium"
      >{task.rightAnswer}</span
    >
  </div>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";
</style>
