<script>
  //
</script>

<div class="AuthLayout">
  <main>
    <slot />
  </main>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";

  .AuthLayout {
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
