<script>
  export let value;
  export let onChange;
  import FaCheck from "svelte-icons/fa/FaCheck.svelte";
</script>

<div class={`checkbox ${$$props.class}`} class:checked={value}>
  <FaCheck />
  <input
    type="checkbox"
    on:click={(e) => e.stopPropagation()}
    on:change={(e) => {
      e.stopPropagation();
      onChange();
    }}
  />
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    border: 1px solid $grey200;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: all 200ms ease;

    position: relative;

    input {
      opacity: 0;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 0;
      margin: 0;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    :global(svg) {
      width: 10px;
      height: 10px;
      fill: white;
      transition: all 200ms ease;
    }

    &.checked {
      border: 1px solid $primary;
      background-color: $primary;
      :global(svg) {
        fill: white;
      }
    }
  }
</style>
