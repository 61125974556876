<script>
  import AppButton from "../../../shared/components/AppButton/AppButton.svelte";
  import AppInput from "../../../shared/components/FormItems/AppInput/AppInput.svelte";

  import AuthForm from "../AuthForm.svelte";
  import { push } from "svelte-spa-router";
  import { login } from "../../../shared/api";
  import { AuthState } from "../../../shared/state/auth";
  import { link } from "svelte-spa-router";

  let email = "";
  let password = "";

  async function handleSubmit() {
    try {
      const { user, token } = await login(email, password);
      AuthState.set({ user, token });
      push("/");
    } catch (e) {
      alert("Credenziali rifiutate");
      console.log(e);
    }
  }

  function toSignup() {
    push("/signup");
  }
</script>

<AuthForm>
  <div class="content">
    <h1 class="heading mb-s">Bentornato,</h1>
    <p class="helper mb-m">
      Inserisci le credenziali per accedere a eLT1. Se non hai ancora un
      account, puoi crearne uno qui. Se hai problemi ad accedere, contattami a
      winkler.developer@gmail.com.
    </p>
    <AppInput
      value={email}
      onChange={(e) => (email = e)}
      class="mb-s"
      label="E-mail"
    />
    <AppInput
      value={password}
      onChange={(p) => (password = p)}
      label="Password"
      type="password"
    />
    <div class="flex w-full justify-end">
      <a
        href="/password-reset"
        use:link
        class="text-sm text-text-muted underline cursor-pointer"
        >Password dimenticata?</a
      >
    </div>
    <AppButton role="primary" class="mb-s mt-m" on:click={handleSubmit}
      >Login</AppButton
    >
    <AppButton on:click={toSignup} role="ghosted">Crea account</AppButton>
  </div>
</AuthForm>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .content {
    .heading {
      font-size: 22px;
      padding: 0;
    }
    .helper {
      font-size: 14px;
      color: $textMuted;
    }
    a {
      color: $textMuted;
      &:visited {
        color: $textMuted;
      }
    }
  }
</style>
