export function useOnclickOutside(node, callback) {
  return {
    subscribe: (onChange) => {
      if (node) {
        function handler(event) {
          if (!node.contains(event.target)) {
            callback();
          }
        }
        document.addEventListener("click", handler);
        return () => document.removeEventListener("click", handler);
      }
      return () => {};
    },
  };
}
