export async function purgeField(form = {}) {
  // remove non-string and non-array fields from the form (they cause problems with persistence)
  const ret = {};
  Object.entries(form).forEach(([exerciseKey, value]) => {
    Object.entries(value).map(([taskKey, taskValue]) => {
      if (typeof taskValue === "string" || Array.isArray(taskValue)) {
        ret[exerciseKey] = { ...ret[exerciseKey], [taskKey]: taskValue };
      }
    });
  });
  return ret;
}

export function buildSubmissionPayload(form) {
  const body = { type: "standard", data: [] };
  Object.entries(form).forEach(([exerciseKey, value]) => {
    const exerciseEntry = { exercise: exerciseKey, answers: [] };
    Object.entries(value).forEach(([taskKey, taskValue]) => {
      let inputValue;
      if (typeof taskValue === "string") {
        inputValue = taskValue;
      } else if (taskValue instanceof File) {
        inputValue = taskValue;
      } else {
        inputValue = JSON.stringify(taskValue);
      }
      const answer = {
        task: taskKey,
        answer: inputValue,
      };
      exerciseEntry.answers.push(answer);
    });
    body.data.push(exerciseEntry);
  });
  return body;
}
