import urljoin from "url-join";

import {
  executeFormDateRequest,
  executeGETRequest,
  executePOSTRequest,
  executePUTRequest,
} from "./base";

const getBaseUrl = () => {
  return !isProd ? "http://localhost:8080" : "https://elt1-api.aronwinkler.com";
};

// -------------------------------------
// Auth
// -------------------------------------

export const login = async (email, password) => {
  const url = urljoin(getBaseUrl(), "/auth/login");
  return await executePOSTRequest(url, null, { email, password });
};

export const signup = async ({ email, password }) => {
  const url = urljoin(getBaseUrl(), "/users/signup");
  const body = { email, password };
  return await executePOSTRequest(url, "", body);
};

// -------------------------------------
// Password reset requests
// -------------------------------------

export const resetPasswordFromSettings = async (
  oldPassword,
  newPassword,
  token
) => {
  const relativeUrl = `/users/update-password`;
  const url = urljoin(getBaseUrl(), relativeUrl);
  const body = { oldPassword, newPassword };
  return executePUTRequest(url, token, body);
};

export const initPasswordResetRequest = async (email) => {
  const relativeUrl = `/password-reset-requests`;
  const url = urljoin(getBaseUrl(), relativeUrl);
  const body = { email };
  return executePOSTRequest(url, "", body);
};

export const resetPasswordFromResetRequest = async (newPassword, requestId) => {
  const relativeUrl = `/password-reset-requests/${requestId}`;
  const url = urljoin(getBaseUrl(), relativeUrl);
  const body = { newPassword };
  return executePUTRequest(url, "", body);
};

// -------------------------------------
// EmailValidationToken
// -------------------------------------

export const confirmEmailAddressWithToken = async (validationToken) => {
  const url = urljoin(getBaseUrl(), "/email-validation-tokens/validate");
  const body = { token: validationToken };
  return await executePOSTRequest(url, "", body);
};

// -------------------------------------
// Standard sections
// -------------------------------------

export const getStandardSectionNames = async (token) => {
  const url = urljoin(getBaseUrl(), "/standard-sections/names");
  return await executeGETRequest(url, token);
};

export const getSheet = async (sections, perSection, token) => {
  const url = urljoin(getBaseUrl(), "/standard-sections/sheet");
  return await executeGETRequest(url, token, {
    "include-sections": sections,
    "exe-per-section": perSection,
  });
};

// -------------------------------------
// Sheets
// -------------------------------------

export const getCustomSheets = async (token) => {
  const url = urljoin(getBaseUrl(), "/sheets");
  return await executeGETRequest(url, token);
};

export const getCustomSheet = async (id, token) => {
  const url = urljoin(getBaseUrl(), `/sheets/${id}`);
  return await executeGETRequest(url, token);
};

// -------------------------------------
// Submissions
// -------------------------------------

export const createSubmission = async (data, token) => {
  const url = urljoin(getBaseUrl(), "/submissions");
  return await executePOSTRequest(url, token, data);
};

export const getSubmissions = async (token, page = 1, filters) => {
  const url = urljoin(getBaseUrl(), "/submissions");
  return await executeGETRequest(url, token, { ...filters, page, limit: 10 });
};

export const getSubmission = async (id, token) => {
  const url = urljoin(getBaseUrl(), `/submissions/${id}`);
  return await executeGETRequest(url, token);
};

// -------------------------------------
// Uploads
// -------------------------------------

export const uploadFile = async (file, token) => {
  const url = urljoin(getBaseUrl(), "/uploads");
  const body = { file };

  return await executeFormDateRequest(url, token, body);
};
