export const translateSheetResponse = (sheet) => {
  return sheet?.map((part) => {
    const { section, exercises } = part;
    const mapped = exercises?.map((exe) => {
      const tasks = exe?.tasks?.map((task) => {
        if (task.type === "fill" || task.type === "SF") {
          return { ...task, rightAnswer: JSON.parse(task.rightAnswer) };
        }
        return task;
      });
      return { ...exe, tasks };
    });
    return { section, exercises: mapped };
  });
};

export const translateCustomSheetResponse = (sheet) => {
  const { title, exercises, type } = sheet;
  return {
    title,
    type,
    exercises: exercises.map((exe) => {
      const tasks = exe?.tasks?.map((task) => {
        if (task.type === "fill" || task.type === "SF") {
          return { ...task, rightAnswer: JSON.parse(task.rightAnswer) };
        }
        return task;
      });
      return { ...exe, tasks };
    }),
  };
};

export async function asyncMap(array, callback) {
  const ret = [];
  for (let i = 0; i < array.length; i++) {
    ret.push(await callback(array[i], i, array));
  }
  return ret;
}
