<script>
  import RadioGroup from "../../FormItems/RadioGroup/RadioGroup.svelte";

  export let task;
  export let value;
  export let onChange;

  $: rightAnswer = task.rightAnswer;
  $: wrongAnswers = task.wrongAnswers;

  $: options = [rightAnswer, ...wrongAnswers].map((a) => ({
    label: a,
    value: a,
  }));
</script>

<div class={`choice ${$$props.class}`}>
  <RadioGroup {options} {value} {onChange} />
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .choice {
  }
</style>
