<script>
  import Heading from "../../../shared/components/Typography/Heading/Heading.svelte";

  //
</script>

<div class={`home ${$$props.class}`}>
  <Heading>Benvenuto alle seconda iterazione di eLT1</Heading>
  <p class="text-lg mt-3">
    Oltre ad un redesign del sito, sono state aggiunte molte funzionalità che
    non era disponibili nella versione inziale.
  </p>
  <p class="mt-1 text-lg">
    Mi auguro che eLT1 ti sia utile in vista dell'esame e che si riveli una
    risorsa che facilita lo studio, accresce l'interazione con docenti/tutor e
    fornisca in generale ad una buona esperinza utente.
  </p>
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .home {
  }
</style>
