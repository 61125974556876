<script lang="ts">
  export let type: "h1" | "h2" | "h3" | "h4";
  export let id: string;
</script>

{#if type === "h1"}
  <h1 class={$$props.class} {id}><slot /></h1>
{:else if type === "h2"}
  <h2 class={$$props.class} {id}><slot /></h2>
{:else if type === "h3"}
  <h3 class={$$props.class} {id}><slot /></h3>
{:else if type === "h4"}
  <h4 class={$$props.class} {id}><slot /></h4>
{:else}
  <h1 class={$$props.class} {id}><slot /></h1>
{/if}

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  h1,
  h2,
  h3,
  h4 {
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: 22px;
    font-weight: 500;
  }
  h2 {
    font-size: 18px;
    font-weight: 500;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
  }
  h4 {
    font-size: 15px;
    font-weight: 500;
  }
</style>
