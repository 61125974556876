<script>
  import AppTextarea from "../../FormItems/AppTextarea/AppTextarea.svelte";

  export let task;
  export let value;
  export let onChange;
</script>

<div class={`open ${$$props.class}`}>
  <AppTextarea {value} {onChange} />
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  /* .open {
  } */
</style>
