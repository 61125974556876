<script>
  //
  import { getLineStyles, getLineWidths, linesStore } from "./service";
  import { push, location } from "svelte-spa-router";
  import { onDestroy, onMount } from "svelte";
  import { logout } from "../../state/auth";
  import { SheetState } from "../../state/sheet";
  import UserActions from "./UserActions/UserActions.svelte";
  import FaPen from "svelte-icons/fa/FaPen.svelte";

  const links = [
    { label: "Home", href: "/" },
    { label: "Esercizi", href: "/esercizi" },
    { label: "Schede", href: "/schede" },
    { label: "Profilo", href: "/profilo" },
  ];

  const itemWidth = 80;
  const itemMargin = 20;

  let store;
  $: sheet = store?.sheet;
  let unlisten;

  $: pathname = $location;
  $: activePath = links.findIndex((link) => link.href === pathname);
  $: lineStyle = getLineStyles(activePath, itemWidth, itemMargin);

  onMount(() => {
    const unsubscribeSheet = SheetState.subscribe((value) => (store = value));
    return () => {
      unsubscribeSheet();
    };
  });

  onDestroy(() => {
    unlisten && unlisten();
  });

  function toSubmission() {
    push("/compilazione");
  }
  //
</script>

<nav class={`header ${$$props.class}`}>
  <div class="logo" on:click={() => push("/")}>
    <img src={"/icons/logo.svg"} alt="" />
  </div>
  <div class="links">
    {#each links as link}
      <div class="link-wrapper flex items-center justify-center">
        <div
          class="link"
          class:active={link.href === pathname}
          on:click={() => push(link.href)}
          style={`width:${itemWidth}px;`}
        >
          {link.label}
        </div>
      </div>
    {/each}
    {#if activePath >= 0}
      <div class="links-line" style={lineStyle} />
    {/if}
  </div>
  <div class="right">
    {#if !!sheet}
      <div class="sheet-edit mr-m" on:click={toSubmission}>
        <FaPen />
      </div>
    {/if}

    <UserActions />
  </div>
</nav>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .header {
    z-index: 100;
    $hpad: 20px;
    height: 60px;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    box-shadow: 0px 6px 12px rgba($color: #000000, $alpha: 0.05);

    position: relative;

    .logo {
      position: absolute;
      left: $hpad;
      height: 45px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .links {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      position: relative;

      .links-line {
        height: 3px;
        background-color: $primary;
        position: absolute;
        top: calc(100% - 1px);
        border-radius: 0px 0px 10px 10px;
        transition: all 200ms ease;
      }

      .link-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:last-child) {
          margin-right: 20px;
        }
        .link {
          cursor: pointer;
          font-weight: 500;
          font-size: 16px;
          transition: all 200ms ease;
          padding: 0px 10px;

          text-align: center;

          &.active {
            color: $primary;
          }
        }
      }
    }
    .right {
      position: absolute;
      right: $hpad;
      display: flex;
      align-items: center;

      .sheet-edit {
        height: 20px;
        width: 20px;
        cursor: pointer;
        :global(svg) {
          fill: $primary;
        }
      }
    }
  }
</style>
