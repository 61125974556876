<script>
  import AppButton from "../../../shared/components/AppButton/AppButton.svelte";
  import AppInput from "../../../shared/components/FormItems/AppInput/AppInput.svelte";

  import AuthForm from "../AuthForm.svelte";
  import { push } from "svelte-spa-router";
  import { AuthState, login } from "../../../shared/state/auth";
  import { signup } from "../../../shared/api";

  let authState;
  let error;

  let formValues = {
    email: "",
    name: "",
    surname: "",
    password: "",
    repeatPassword: "",
  };

  let submitting = false;

  AuthState.subscribe((value) => (authState = value));

  function update(field) {
    return (value) => (formValues = { ...formValues, [field]: value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    submitting = true;
    if (formValues.password === formValues.repeatPassword) {
      if (!formValues.email.endsWith("unitn.it")) {
        error = "Sono validi solo gli indirizzi unitn.it";
      } else {
        const body = { email: formValues.email, password: formValues.password };
        try {
          await signup(body);
          push("/check-mail");
        } catch (e) {
          console.log(e);
          error = "C'è stato un errore durante la registrazione.";
        }
      }
    } else {
      error = "Le password non combaciano";
    }
    submitting = false;
  }

  function toLogin() {
    push("/");
  }
</script>

<AuthForm>
  <form on:submit={handleSubmit} class="content">
    <h1 class="heading mb-s">Crea il tuo account</h1>
    <p class="helper mb-m">
      Per creare un account, hai bisogno della tua mail universitaria e di una
      password.
    </p>
    {#if error}
      <p class="text-error my-2">{error}</p>
    {/if}
    <AppInput
      value={formValues?.email}
      onChange={update("email")}
      class="mb-s"
      label="E-mail"
    />
    <AppInput
      value={formValues.password}
      onChange={update("password")}
      class="mb-s"
      label="Password"
      type="password"
    />
    <AppInput
      value={formValues.repeatPassword}
      onChange={update("repeatPassword")}
      label="Ripeti password"
      type="password"
    />
    <AppButton
      loading={submitting}
      role="primary"
      class="mb-s mt-m"
      type="submit">Registrati</AppButton
    >
    <AppButton role="ghosted" on:click={toLogin}>Effettua l'accesso</AppButton>
  </form>
</AuthForm>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .content {
    .heading {
      font-size: 22px;
      padding: 0;
    }
    .helper {
      font-size: 14px;
      color: $textMuted;
    }
  }
</style>
