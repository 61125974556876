<script>
  import AuthApp from "./AuthApp/AuthApp.svelte";

  import LoggedApp from "./LoggedApp/LoggedApp.svelte";
  import { AuthState } from "../shared/state/auth";
  import { onDestroy } from "svelte";

  let user;
  const unsubsribe = AuthState.subscribe((value) => (user = value.user));

  onDestroy(() => {
    unsubsribe();
  });
</script>

<div class="RoutedApp">
  {#if !!user}
    <LoggedApp />
  {:else}
    <AuthApp />
  {/if}
</div>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
</style>
