import dayjs from "dayjs";
import { writable } from "svelte/store";

import { getSubmissions } from "../../../shared/api";

const intialState = {
  filters: {
    type: "off",
    after: dayjs().subtract(1, "week").toISOString(),
    before: dayjs().toISOString(),
  },
  page: 1,
  loading: false,
  canLoadMore: false,
  submissions: [],
};

function deduplicate(s = []) {
  return s.filter(
    (a, index, self) => index === self.findIndex((b) => b._id === a._id)
  );
}

const createStore = () => {
  const { set, subscribe, update } = writable(intialState);

  const load = async (token, filters) => {
    update((s) => ({ ...s, loading: true }));
    const { data, count } = await getSubmissions(token, 1, filters);
    update((state) => {
      return {
        ...state,
        submissions: data,
        canLoadMore: data.length < count,
        loading: false,
        page: 1,
        filters,
      };
    });
  };

  const loadNext = async (token, page, filters) => {
    update((s) => ({ ...s, loading: true }));

    const { data, count } = await getSubmissions(
      token,
      page + 1,
      filters
    ).catch(() => ({ data: [], count: 0 }));

    update((state) => {
      const newSubmissions = deduplicate([...state.submissions, ...data]);
      return {
        ...state,
        submissions: newSubmissions,
        canLoadMore: newSubmissions.length < count,
        loading: false,
        page: page + 1,
      };
    });
  };

  return { subscribe, set, update, load, loadNext };
};

export const SubmissionParamsStore = createStore();
