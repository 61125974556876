<script>
  import { push } from "svelte-spa-router";

  import {
    initPasswordResetRequest,
    resetPasswordFromResetRequest,
  } from "../../../shared/api";

  import AppButton from "../../../shared/components/AppButton/AppButton.svelte";

  import AppInput from "../../../shared/components/FormItems/AppInput/AppInput.svelte";

  import AuthForm from "../AuthForm.svelte";

  // ---------------------------------------
  // State declarations
  // ---------------------------------------

  let newPassword;
  let repeatPassword;
  let error;
  let ok;
  let loading = false;

  export let params = {};

  // ---------------------------------------
  // Effects
  // ---------------------------------------

  // ---------------------------------------
  // Lifecycle
  // ---------------------------------------

  // ---------------------------------------
  // Component functions
  // ---------------------------------------

  async function handleSubmit(e) {
    e.preventDefault();
    loading = true;
    try {
      if (newPassword) {
        if (newPassword === repeatPassword) {
          await resetPasswordFromResetRequest(newPassword, params.token);
          newPassword = "";
          repeatPassword = "";
          push("/password-reset-success");
        } else {
          error = "Le password non combiaciano";
        }
      } else {
        error = "Devi inserire una password";
      }
    } catch (e) {
      if (e.errorCode === "ERR_TOKEN_ALREADY_USED") {
        error =
          "Non è più possibile usare questo link per il reset della password";
      } else {
        console.log(e);
        error = "Errore durante la richiesta";
      }
    }
    loading = false;
  }
</script>

<AuthForm class={`PasswordReset ${$$props.class}`}>
  <form on:submit={handleSubmit} class="content">
    <h1 class="heading mb-s">Impostazione nuova password</h1>
    <p class="helper mb-m">Inserisci di seguito la tua nuova password.</p>
    {#if error}
      <span class="block text-error mb-2 font-medium text-sm">{error}</span>
    {/if}

    <AppInput
      value={newPassword}
      onChange={(v) => (newPassword = v)}
      label="Nuova password"
      class="mb-3"
      type="password"
    />
    <AppInput
      value={repeatPassword}
      onChange={(v) => (repeatPassword = v)}
      label="Ripeti password"
      class="mb-3"
      type="password"
    />
    <AppButton {loading} type="submit" class="mb-2">Conferma</AppButton>
    <AppButton {loading} type="button" on:click={() => push("/")} role="ghosted"
      >Torna al login</AppButton
    >
  </form>
</AuthForm>

<style lang="scss">
  @import "src/shared/theme/theme.scss";
  @import "src/shared/theme/globals.scss";

  .PasswordReset {
    .content {
      .heading {
        font-size: 22px;
        padding: 0;
      }
      .helper {
        font-size: 14px;
        color: $textMuted;
      }
    }
  }
</style>
